import { useState, useEffect } from "react";
import QuestionManager from "../managers/QuestionManager";

export default function QuestionsTable() {
  let questionManager = new QuestionManager();
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    questionManager.getQuestions().then((questions) => {
      setQuestions(questions);
    });
    setLoading(false);
  }, []);

  return (
    <div>
      <p>Use !nanask [Question] to ask a question</p>
      <p>Use !nanclear to clear all questions</p>
      <br />
      {loading ? (
        <p>Loading</p>
      ) : questions.length === 0 ? (
        <p>No questions</p>
      ) : (
        <table>
          <th>ID</th>
          <th>Question</th>
          <th>Asked By:</th>
          {questions.map((question) => {
            return (
              <tr>
                <td>{question.id}</td>
                <td>{question.question}</td>
                <td>{question.asker}</td>
              </tr>
            );
          })}
        </table>
      )}
    </div>
  );
}
