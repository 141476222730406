export default class Question {
  id = null;
  question = null;
  asker = null;

  constructor(id, question, asker) {
    this.id = id;
    this.question = question;
    this.asker = asker;
  }
}
