import "./App.css";
import QuestionsTable from "./components/QuestionsTable";

function App() {
  return (
    <div className="App">
      <header>
        <h1>Noobs Ask Noobs Questions</h1>
      </header>
      <main>
        <QuestionsTable />
      </main>
    </div>
  );
}

export default App;
