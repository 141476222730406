import Question from "../models/Question.js";
import Config from "../config.js";

export default class QuestionManager {
  constructor() {
    this.questions = [];
  }

  async getQuestions() {
    console.log(this.host);
    let results = await fetch(Config.backendHost);
    let data = await results.json();
    this.questions = [];
    for (let question of data) {
      this.questions.push(
        new Question(question.id, question.question, question.asker)
      );
    }
    return this.questions;
  }
}
